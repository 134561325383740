// import { logger } from "./logging/log-util";

export const requestBiddingFromPWT = () => {
    // logger.info("requestBiddingFromPWT");
    if (typeof PWT.requestBids === "function") {
      PWT.requestBids(
        PWT.generateConfForGPT(googletag.pubads().getSlots()),
        function (adUnitsArray) {
          // logger.info("bids received from PWT", adUnitsArray);
          PWT.addKeyValuePairsToGPTSlots(adUnitsArray);
          PWT.ow_BidsReceived = true;
          window.initAdserver(false);
        }
      );
    }
    var FAILSAFE_TIMEOUT = PWT.timeout||1000; // this timeout should > OpenWrap and TAM timeout
    // console.log("initAdServer", FAILSAFE_TIMEOUT)
    setTimeout(function () {
      if(!window.initAdserverFlag){
        window.initAdserver(true); 
      } // calling this function with forced mode set to true so that GPT API is always executed
    }, FAILSAFE_TIMEOUT);
  };
  
  export function apstagBidCallback(bids) {
    // logger.info("apstagBidCallback - received bids", bids);
    googletag.cmd.push(function () {
      apstag.setDisplayBids();
      PWT.TAM_BidsReceived = true;
      window.initAdserver(false);
    });
  };
  
  export const DEFAULT_HOME_MOBILE = [
    {
      slotID: "div-gpt-ad-1657866020444-0",
      sizes: [[300, 250]],
    },
    {
      slotID: "div-gpt-ad-1657866205249-0",
      sizes: [[300, 250]],
    },
    {
      slotID: "div-gpt-ad-1657866282643-0",
      sizes: [[300, 250]],
    },
    {
      slotID: "div-gpt-ad-1657866338810-0",
      sizes: [[300, 250]],
    },
    {
      slotID: "div-gpt-ad-1657866406380-0",
      sizes: [[300, 250]],
    },
    {
      slotID: "div-gpt-ad-1657866608917-0",
      sizes: [[320, 50]],
    },
  ];
  
  export const createApsSlotsRosDesktopFalse = ({
    atf = false,
    atf300 = false,
    mtf1 = false,
    mtf2 = false,
    mtf3 = false,
    btf = false,
  }) => {
    createApsSlotsRosDesktop({ atf, atf300, mtf1, mtf2, mtf3, btf });
  };
  
  export const createApsSlotsRosDesktop = ({
    atf = true,
    atf300 = true,
    mtf1 = true,
    mtf2 = true,
    mtf3 = true,
    btf = true,
  }) => {
    let slots = [];
    if (atf) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-0",
        sizes: [[728, 90]],
      });
    }
    if (atf300) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-1",
        sizes: [[300, 250]],
      });
    }
    if (mtf1) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-2",
        sizes: [[728, 90]],
      });
    }
    if (mtf2) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-3",
        sizes: [[728, 90]],
      });
    }
    if (mtf3) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-4",
        sizes: [[728, 90]],
      });
    }
    if (btf) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-5",
        sizes: [[728, 90]],
      });
    }
    return slots;
  };
  
  // Just a warpper with default false value
  export const createApsSlotsRosMobileFalse = ({
    atf = false,
    mtf1 = false,
    mtf2 = false,
    mtf3 = false,
    btf = false,
    bottom = false,
  }) => {
    createApsSlotsRosMobile({ atf, mtf1, mtf2, mtf3, btf, bottom });
  };
  export const createApsSlotsRosMobile = ({
    atf = true,
    mtf1 = true,
    mtf2 = true,
    mtf3 = true,
    btf = true,
    bottom = true,
  }) => {
    let slots = [];
    if (atf) {
      slots.push({
        slotID: "div-gpt-ad-1657866675439-0",
        sizes: [[300, 250]],
      });
    }
    if (mtf1) {
      slots.push({
        slotID: "div-gpt-ad-1657866796114-0",
        sizes: [[300, 250]],
      });
    }
    if (mtf2) {
      slots.push({
        slotID: "div-gpt-ad-1657866859190-0",
        sizes: [[300, 250]],
      });
    }
    if (mtf3) {
      slots.push({
        slotID: "div-gpt-ad-1657866859190-1",
        sizes: [[300, 250]],
      });
    }
    if (btf) {
      slots.push({
        slotID: "div-gpt-ad-1657866910339-0",
        sizes: [[300, 250]],
      });
    }
    if (bottom) {
      slots.push({
        slotID: "div-gpt-ad-1657866963317-0",
        sizes: [[320, 50]],
      });
    }
    return slots;
  };
  export const createApsSlotsHomeDesktop = ({
    atf = true,
    atf300 = true,
    mtf1 = true,
    mtf2 = true,
    mtf3 = true,
    btf = true,
  }) => {
    let slots = [];
    if (atf) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-0",
        sizes: [[728, 90]],
      });
    }
    if (atf300) {
      slots.push({
        slotID: "div-gpt-ad-1702380047999-0",
        sizes: [[300, 250]],
      });
    }
    if (mtf1) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-1",
        sizes: [[728, 90]],
      });
    }
    if (mtf2) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-2",
        sizes: [[728, 90]],
      });
    }
    if (mtf3) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-3",
        sizes: [[728, 90]],
      });
    }
    if (btf) {
      slots.push({
        slotID: "div-gpt-ad-1702380047988-4",
        sizes: [[728, 90]],
      });
    }
    return slots;
  };
  export const createApsSlotsHomeMobile = ({
    atf = true,
    mtf1 = true,
    mtf2 = true,
    mtf3 = true,
    btf = true,
    bottom = true,
  }) => {
    let slots = [];
    if (atf) {
      slots.push({
        slotID: "div-gpt-ad-1657866020444-0",
        sizes: [[300, 250]],
      });
    }
    if (mtf1) {
      slots.push({
        slotID: "div-gpt-ad-1657866205249-0",
        sizes: [[300, 250]],
      });
    }
    if (mtf2) {
      slots.push({
        slotID: "div-gpt-ad-1657866282643-0",
        sizes: [[300, 250]],
      });
    }
    if (mtf3) {
      slots.push({
        slotID: "div-gpt-ad-1657866338810-0",
        sizes: [[300, 250]],
      });
    }
    if (btf) {
      slots.push({
        slotID: "div-gpt-ad-1657866406380-0",
        sizes: [[300, 250]],
      });
    }
    if (bottom) {
      slots.push({
        slotID: "div-gpt-ad-1657866608917-0",
        sizes: [[320, 50]],
      });
    }
    return slots;
  };
  