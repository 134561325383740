const stateLangMapper = {
  uptak: "यूपी",
  mbtak: "मुंबई",
  rjtak: "राजस्थान",
  mptak: "एमपी",
  gjtak: "गुजरात",
  cgtak: "छत्तीसगढ़",
};
const stateLinkMapper = {
  uptak: "https://www.uptak.in/",
  mbtak: "https://www.mumbaitak.in/",
  rjtak: "https://www.rajasthantak.com/",
  mptak: "https://www.mptak.in/",
  gjtak: "https://www.gujarattak.in/",
  cgtak: "https://www.chhattisgarhtak.in/",
};

const defaultAuthorMap ={
  uptak: {
    link:"https://www.uptak.in/author/yuupii-tk",
    name:"यूपी तक"
  },
  mbtak: {
    link:"https://www.mumbaitak.in/author/mumbai-tak",
    name:"मुंबई तक"
  },
  rjtak: {
    link:"https://www.rajasthantak.com/author/rajasthantakwebsite",
    name:"राजस्थान तक"
  },
  mptak: {
    link:"https://www.mptak.in/author/mptak-web",
    name:"एमपी तक"
  },
  gjtak: {
    link:"https://www.gujarattak.in/author/gujarattak",
    name:"गुजरात तक"
  },
  cgtak: {
    link:"https://www.chhattisgarhtak.in/author/chhatishgarhtak",
    name:"छत्तीसगढ़ तक"
  },
}
export  {stateLangMapper,stateLinkMapper,defaultAuthorMap};